@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap");

*,
html {
	font-family: "Barlow", sans-serif !important;
	color: white;
	-webkit-font-smoothing: antialiased;
	position: relative;
	box-sizing: border-box;
}

body {
	background-color: #070720;
	line-height: 1.35;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
	font-weight: 400;
	margin: 0;
}

h1 {
	display: block;
	font-size: 4em;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

img {
	border: 0;
	vertical-align: middle;
}

svg {
	fill: white;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
	text-decoration: none;
	outline: none;
}

.rootContainer {
	max-width: 100%;
}

.pageContentLandingPage {
	display: flex;
}

.mainContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
}

.contentWrapper {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.content {
	min-width: auto;
	width: 100%;
	display: flex;
	margin: 0 auto;
	z-index: 100;
}

.signupContainer {
	display: flex;
	color: #fff;
	flex-direction: column;
	align-items: flex-start;
	font-size: 16px;
	margin-top: 60px;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	background-color: #11112a;
	border-radius: 6px;
	max-width: 500px;
	padding: 48px;
}

.cardListForm {
	width: 100%;
	font-size: 1rem;
}

.signupForm {
	margin: auto;
	font-size: 1rem;
}

.signupLogoContainer {
	margin-bottom: 48px;
}

.signupLogoContainer {
	margin-bottom: 48px;
}

.loginTextContainer {
	font-size: 26px;
	font-weight: 500;
	font-family: Barlow;
	color: #fff;
	margin-bottom: 12px;
}

.signupAltTextContainer {
	font-size: 17px;
	font-weight: 500;
	color: #cddcfe;
	margin-bottom: 45px;
}

.signupLogin {
	color: #3273fa;
	text-decoration: underline;
	cursor: pointer;
}

.signupCustomInputContainer {
	width: 100%;
	margin-bottom: 24px;
}

.cardListCustomInputContainer {
	display: flex;
	width: 100%;
	margin-bottom: 4px;
}

.signupInputLabelContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.signupInputLabelContainerTwo {
	position: relative;
}

.signupInputLabelContainerTwo input {
	font-size: 17px;
	color: #fff;
	width: 400px;
	height: 42px;
	box-shadow: 0 2px 4px rgb(14 37 84 / 22%);
	border-radius: 3px;
	border: 1px solid #31334a;
	background-color: #25254b !important;
	padding: 0 9px;
	font-weight: 500;
}

.signupInputLabelContainerTwo input:-webkit-autofill {
	box-shadow: 0 0 0 1000px #25254b inset !important;
	-webkit-box-shadow: 0 0 0 1000px #25254b inset !important;
	-webkit-text-fill-color: #fff !important;
	caret-color: #fff;
	background-clip: content-box !important;
}

.signupInputLabel {
	color: #cddcfe;
	font-family: Helvetica Neue, Roboto, Arial;
	font-size: 15px;
	margin-bottom: 10px;
}

.loginRightLabel {
	color: #3273fa;
	font-size: 15px;
}

.signupError {
	border-radius: 3px;
	width: 100%;
	height: 42px;
	margin-bottom: 24px;
	font-size: 14px;
	background-color: orange;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: 700;
}

.signupSubmit {
	cursor: pointer;
	font-size: 17px;
	height: 42px;
	border-radius: 4px;
	background-color: #08a6ff;
	border: none;
	position: relative;
	transition: background-color 0.3s, border-color 0.3s;
	overflow: hidden;
	border-color: #08a6ff;
	padding: 0 15px;
	min-width: 78px;
	min-height: 36px;
	font-weight: 700;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1.35;
	text-align: left;
}

.loginSubmit {
	margin-top: 24px;
	margin-bottom: 24px;
}

.signupButtonLabel {
	opacity: 1;
	transform: scale(1);
}

.signupTerms {
	font-size: 13px;
	color: #cddcfe;
	margin-bottom: 24px;
}

.signupForgot {
	color: #fff;
	text-decoration: underline;
	outline: none;
}

.recoverCompletedContainer {
	margin-top: 12px;
	margin-bottom: 0;
	font-size: 16px;
	color: #cddcfe;
}

.recoverAltTextContainer {
	font-size: 17px;
	font-weight: 500;
	color: #cddcfe;
	margin-bottom: 20px;
}

.settingsIcon svg {
	width: 14px;
	height: 14px;
}

.masterHeader {
	height: 60px;
}

.masterHeaderContainer {
	font-size: 1.4rem;
	top: 0;
	position: fixed;
	width: 100%;
	height: 60px;
	background-color: #0b0b23;
	color: #fff;
	z-index: 300;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	border-bottom: 1px solid #31334a;
	box-sizing: content-box;
}

.logoNav {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 15px;
	height: 100%;
	border-right: 1px solid #31334a;
}

.logoContainer {
	display: flex;
	align-items: center;
}

.logo {
	border: 0;
	vertical-align: middle;
}

.masterHeaderAccountInfo {
	display: flex;
	justify-content: center;
	align-items: center;
}

.masterHeaderUserProfile {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.userProfileLink {
	display: flex;
	align-items: center;
}

.userProfileLink span {
	font-size: 14px;
	font-weight: 700;
	max-width: 100px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.userProfileIcon {
	margin-right: 8px;
	flex: 0 0 26px;
	width: 26px;
	height: 26px;
	border-radius: 3px;
	background-color: #28283e;
}

.settingsMenu {
	width: 146px;
	padding: 0 12px;
	box-shadow: 0 2px 10px 1px #0b0b23;
	background-color: #222238;
}

.settingsMenu:not(:last-child) {
	margin-right: 20px;
}

.settingsMenu:last-child {
	margin-right: 10px;
}

.menuItemContainer {
	cursor: pointer;
}

.menuItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
}

.menuItemLabel {
	margin: auto;
	color: #cddcfe;
	font-size: 13px;
	font-weight: 500;
}

.menuItemIcon {
	margin-top: -9px;
}

.menuItemIcon svg {
	width: 9px;
	height: 9px;
}

.menuItemIcon svg path {
	fill: #cddcfe;
}

.loaderContainer {
	width: -webkit-fill-available;
	height: 550px;
	margin: auto;
}

.loaderIcon {
	top: 45%;
	width: inherit;
	background-color: transparent;
}

.isLoading {
	white-space: nowrap;
	display: inline-block;
	height: 36px;
}

.isLoading:after {
	content: " ";
	display: block;
	width: 25px;
	height: 25px;
	margin: auto;
	border-radius: 50%;
	border: 2px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: isLoading 1.2s linear infinite;
}

.cardListAltContainer {
	font-size: 17px;
	font-weight: 500;
	color: #8cd6d5;
	margin-top: 40px;
	margin-bottom: 17px;
}

@keyframes isLoading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
